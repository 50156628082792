/* src/components/SplashScreen.css */
.splash-screen {
    display: flex;
    flex-direction: column;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: #68179e; 
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
    & img {
        max-width: 900px;
    }
}

.splashSpinner {
    border: 8px solid #67179e;
    border-top: 8px solid #fff;
    border-radius: 50%;
    width: 60px;
    height: 60px;
    animation: spin 0.5s linear infinite;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

@media screen and (max-width: 768px) {
    /* src/components/SplashScreen.css */
.splash-screen {
    & img {
        max-width: 200%;
    }
}
}
