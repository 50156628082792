.cadastro-main {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100vw;
    margin-top: -10px;
    position: fixed;
    height: 101vh;
    z-index: 1;
    background-image: url('../../images/login-background.jpg');
    background-size: cover;
    background-position: center;
}

.cadastro-main::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #67179e;
    background: linear-gradient(to right, #67179e, #2a0845);
    opacity: 0.4;
    z-index: 1;
}

.cadastro-main .close {
    position: absolute;
    top: 10px;
    left: 10px;
    z-index: 2;
    & span {
        color: #fff;
    }
}

.cadastro {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    width: 90%;
    min-width: 300px;
    max-width: 800px;
    max-height: 800px;
    z-index: 2;
    border-radius: 5px;
    background-color: #fff;
    overflow: auto;
    & h1 {
        margin-bottom: 20px;
    }
    & label {
        width: 250px;
    }
    
}

.cadastro-form .label-input{
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    width: 40%;
    margin-right: 5%;
    margin-bottom: 5px;
}

#comercianteImages { 
    width: 85% !important;
}

.cadastro-form #descricao{
    width: 85%;
}

.cadastro-form {
    display: flex;
    justify-content: center;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    height: 100%;
    overflow-y: auto;
    overflow: auto;
    align-items: center;
    & .input-div {
        width: 100% !important;
        margin-left: 10px;
        padding: 10px;
        display: flex;
        align-items: center;
        border: solid 2px #d1d1d1;
        background-color: #fff;
        border-radius: 5px 5px 5px 5px;
        & span {
            color: #67179e;
        }
      }
    & select {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        border: none;
        outline: none;
        font-size: 16px;
        width: 100%;
    }
    & option {
        border: none;
        outline: none;
    }
    & textarea {
        border: none;
        outline: none;
        font-size: 16px;
        resize: none;
        height: 100px;
        width: 95%;  
        font-weight: 500;
    }
}
  
.link-senha {
    text-decoration: none;
    justify-content: flex-start;
    color: gray;
}
  
.link-cad {
    text-decoration: none;
    justify-content: flex-start;
    color: #fff;
}
  
.acess-button {
    background: #6441A5;  /* fallback for old browsers */
    background: -webkit-linear-gradient(to left, #2a0845, #6441A5);  /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to left, #2a0845, #6441A5); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    color: #fff;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
    border: solid 2px #fff;
    cursor: pointer;
}

.cidades-list {
    position: absolute; 
    background-color: white;
    border: 1px solid #d1d1d1;
    height: 200px;
    overflow-y: auto;
    padding: 8px;
    margin-top: 250px;
    cursor: pointer;
    & li {
        padding: 5px;
        border-radius: 5px;
    }
    & li:hover {
        background-color: #d1d1d1;
    }
}

.city-list li {
    padding: 10px; /* Espaçamento interno */
    cursor: pointer; /* Muda o cursor ao passar o mouse */
}

.city-list li:hover {
    background-color: #f0f0f0; /* Destaque ao passar o mouse */
}

.comercianteImages-div {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
    & div {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 40%;
        margin-right: 10px;
    }
}

.images-div {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    
}

.cropped-image-preview {
    width: 130px;
    height: 90px;
    margin-bottom: 5px;
    border-radius: 5px;
    object-fit: cover;
}

@media screen and (max-width: 768px) {
    .cadastro-form .label-input{
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        width: 100%;
        margin-right: 5%;
        margin-bottom: 5px;
    }
    .cadastro {
        height: 600px;
    }
    .cadastro-form {
        & .input-div {
            width: 90% !important;
        }        
    }
    .cidades-list {
        top: 50%;
        margin-top: 0;
        margin-left: 20px;
        height: 200px;
    }
    h1 {
      font-size: 20px;
    }
    .comercianteImages-div {
        & div {
            width: 100%;
            margin-right: 10px;
        }
    }
    
  
}
  
@media screen and (max-width: 400px) {
    h1 {
      font-size: 18px;
    }
  
}
  