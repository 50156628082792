.loading-container {
    display: flex;
    flex-direction: column;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
    & img {
        max-width: 300px;
    }
}

.spinner {
    border: 8px solid #fff;
    border-top: 8px solid #68179e;
    border-radius: 50%;
    width: 60px;
    height: 60px;
    animation: spin 0.5s linear infinite;
    margin-top: 0px;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}
  