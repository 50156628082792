.login-main {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100vw;
    margin-top: -10px;
    position: fixed;
    height: 101vh;
    z-index: 1;
    background-image: url('../../images/login-background.jpg');
    background-size: cover;
    background-position: center;
}

.login-main::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #67179e;
    background: linear-gradient(to right, #67179e, #2a0845);
    opacity: 0.4;
    z-index: 1;
}

.login-main .close {
    position: absolute;
    top: 10px;
    left: 10px;
    z-index: 2;
    & span {
        color: #fff;
    }
}

.login {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    width: 90%;
    min-width: 300px;
    max-width: 500px;
    height: 600px;
    z-index: 2;
    border-radius: 5px;
    background-color: #fff;
    & h1 {
        margin-bottom: 10px;
        justify-content: center;
        align-self: center;
    }
    & label {
        margin-top: -10px;
    }
    & img {
        margin-top: 10px;
        max-width: 300px;
        max-height: 300px;
    }
}
  
.link-senha {
    text-decoration: none;
    justify-content: flex-start;
    color: gray;
}
  
.link-cad {
    text-decoration: none;
    justify-content: flex-start;
    color: #fff;
}
  
.acess-button {
    background: #6441A5;  /* fallback for old browsers */
    background: -webkit-linear-gradient(to left, #2a0845, #6441A5);  /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to left, #2a0845, #6441A5); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    color: #fff;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
    border: solid 2px #fff;
}

@media screen and (max-width: 600px) {
    h1 {
      font-size: 20px;
    }
  
}
  
@media screen and (max-width: 400px) {
    h1 {
      font-size: 18px;
    }
  
}
  