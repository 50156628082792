.categoria-lista-main {
    width: 100%;
    height: auto;
    & label {
        font-size: 18px;
        font-style: italic;
    }
}

.categoria-lista-main {
    width: 100%;
    height: auto;
    & label {
        font-size: 18px;
        font-style: italic;
    }
}

.categoria-lista-main .input-div {
    margin-bottom: 5px;
}

.categoria-lista-main .input-div .search-bar {
    border: none;
    outline: none;
    width: 100%;
    font-size: 19px;
}

.categoria-lista-main .card {
    padding-top: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: 9px;
    width: 170px;
    border-radius: 8px;
    border: #9e9e9e solid 1px;
    min-height: 145px;
    background-color: #f5f5f5;
    & p {
        font-size: 15px;
        font-weight: 500;
        text-align: center;
        color: #000;
        margin: 0;
        word-wrap: break-word;
        white-space: normal;
    }
    & h1 {
        font-size: 16px;
        color: #67179e;
        margin-top: 0;
        margin-bottom: 0;
        margin: 0;
        word-wrap: break-word;
        white-space: normal;
        padding-bottom: 5px;
    }
    & span {
        color: #67179e;
    }
    & div {
        display: flex;
        flex-direction: row;
    }
}

.div-title-categoria {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    margin-top: -10px;
    & span{
        color: #67179e;
    }
    & p {
        font-size: 16px;
    }
}


.categoria-lista-main .card .profile-image {
    width: 100px;
    height: 100px;
    object-fit: cover; 
    border-radius: 8px;
    overflow: hidden;
  }

.categoria-lista-main .comerciantes {
    display: flex;
    justify-content: center;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    padding: 10px;
    width: 101%;
    margin-bottom: 60px;
    margin-left: -16px;
    & .card {
        margin-bottom: 10px;
    }
}

.categoria-lista-button {
    background: #fff;
    color: #000;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 0;
    width: 100%;
    & span {
        margin-bottom: 10px;
        color: #67179e;
    }
}

.modal-tipo {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
    overflow-y: auto;
  }

.close-modal-tipo {
    position: relative;
    margin-left: -95%;
    margin-top: -6%;
    background-color: #fff;
    & span {
        color: #67179e;
    }
}
  
  .modal-content-tipo {
    display: flex;
    align-items: center;
    flex-direction: column;
    background: white;
    height: 90%;
    overflow-y: auto;
    padding: 20px;
    border-radius: 5px;
    width: 300px;
    text-align: center;
    font-size: 17px;
    font-weight: 500;
    & img {
        max-width: 200px;
    };
    & h2 {
        margin-top: -5%;
        font-size: 26px;
    }
    & span {
        color: #67179e;
    }
  }
  
  .modal-content-tipo ul {
    list-style: none;
    padding: 0;
  }
  
  .modal-content-tipo li {
    padding: 10px;
    cursor: pointer;
  }
  
  .modal-content-tipo li:hover {
    background-color: #f0f0f0; /* Efeito hover */
  }

  .li-div-tipo {
    display: flex;
    align-items: center;
}
  
.header-desktop {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    width: 100%;
    top: 0;
    max-height: 10%;
    background-color: #fff;
    padding: 5px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.15);
}

.header-desktop .content {
    display: flex;
    width: 70%;
    height: 10%;
    align-items: center;
    justify-content: space-between;
    & img {
        max-width: auto;
        max-height: 9vh;
        cursor: pointer;
    }
    & .login-button-desktop {
        display: flex;
        align-items: center;
        color: #fff;
        background: #67179E;
        cursor: pointer;
        & span {
            color: #fff;
        }
    }
}

.header-desktop .input-div {
    margin-bottom: 0;
    width: 40%;
    & .search-bar {
        width: 100%;
        border: none;
        outline: none;
        font-size: 18px;
    }
}

.header-categoria-desktop {
    display: flex;
    flex-direction: column;
    align-items: center;
    & label {
        font-size: 16px;
    }
    & .categorias-title  {
        cursor: pointer;
        & label {
            padding-left: 0;
            cursor: pointer;
        }
    }
    & .categorias-dropdown {
        display: none;
        flex-direction: column;
        position: absolute;
        margin-top: 20px;
        box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;  
    }
    & div {
        width: 120px;
        display: flex;
        align-items: left;
        justify-content: center;
        flex-direction: row;
        background-color: #fff;
        & div{
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: row;
            & span {
                font-size: 18px;
                color: #67179e;
                margin-right: 2px;
                padding-bottom: 9px;
            }
        }
        & h1 {
            font-size: 16px;
        }
        & span {
            font-size: 18px;
            color: #67179e;
        }
    }
}

.categorias-dropdown :hover {
    background-color: #f0f0f0;
}

.header-categoria-desktop:hover .categorias-dropdown {
    display: flex;
}

.div-title-categoria-desktop {
    display: flex;
    align-items: center;
    justify-content: left;
    flex-direction: row;
    & span{
        color: #67179e;
    }
    & p {
        font-size: 16px;
    }
}

.comerciantes {
    display: flex;
    justify-content: center;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    padding: 10px;
    width: 101%;
    margin-bottom: 60px;
    margin-left: -16px;
    & .card {
        margin-bottom: 10px;
        & #destaque {
            position: absolute;
            color: #FBC02D;
            margin-left: -20px;
            margin-top: -10px;
        }
    }
}