.comerciante-details-desktop {
    width: 100%;
    min-height: 70vh;
    overflow-x: hidden;
    overflow-y: auto;
}

.comerciante-details-desktop .title {
    display: flex;
    margin-top: -10px;
    flex-direction: row;
    justify-content: space-between;
    padding-left: 5px;
    align-items: center;
    & span {
        font-weight: bold;
        color: #000;
    }
}

.comerciante-details-desktop .profile-image-block {
    display: flex;
    flex-direction: row;
    & img {
        width: 110px;
        height: 110px;
        border-radius: 5px;
        object-fit: cover;
        cursor: pointer;
    }
}

.comerciante-details-desktop .profile-image-block .main-info{
    display: flex;
    flex-direction: column;
    height: 100px;
    justify-content: center;
    & label{
        margin-left: 15px;
        padding-bottom: 5px;
        padding-left: 0;
        font-size: 18px;
    }
    & .servico {
        padding: 5px 10px 5px 10px;
        border-radius: 5px;
        background-color: #9575CD;
        color: #fff;
    }
}

.comerciante-details-desktop .profile-image-block .tipo-contrato {
    display: flex;
    flex-direction: row;
    height: auto;
    align-items: center;
    & label {
        font-size: 18px;
        display: flex;
        align-items: center;
    }
}

.comerciante-details-desktop .content div{
    display: flex;
    flex-direction: column;
    & div {
        margin-top: 15px;
        display: flex;
        flex-direction: row;
        & label {
            padding-left: 5px;
            color: #7e7e7e;
            font-size: 16px;
            font-weight: 500;
        }
    }
    & span {
        font-size: 20px;
    }
    & label {
        padding: 0;
        font-size: 18px;
    }
    & .numero{
        height: auto;
        display: flex;
        align-items: center;
        margin-top: 0;
        & label {
            font-size: 16px;
            padding: 0;
            color: #000;
        }
    }
    & .descricao {
        min-height: 100px;
        max-height: 100px;
    } 
}

.image-thumbnail {
    width: 100px;
    height: 100px;
    object-fit: cover;
    margin: 5px;
    cursor: pointer;
}

.modal-image {
    max-width: 80%;
    min-width: 80%;
    object-fit: cover;
    border-radius: 5px;
    max-height: 70%;
}

.close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: #fff;
    border: none;
    padding: 10px;
    cursor: pointer;
}

.copy-button {
    margin-left: 10px;
    padding: 0;
    background-color: #fff;
    border: none;
    cursor: pointer;
}

.socials-desktop {
    display: flex;
    flex-direction: column;
}

.socials-desktop div {
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}

.socials-desktop .whatsapp {
    width: 100%;
    background-color: #64B161;
    border-radius: 5px;
    cursor: pointer;
}

.socials-desktop .instagram {
    width: 100%;
    background: #833ab4;
    background: linear-gradient(
        to right,
        #833ab4,#fd1d1d,#fcb045
    );
    border-radius: 5px;
    cursor: pointer;
}

.socials-desktop img {
    width: 35px;
    height: 35px;
    border-radius: 5px;
    margin-right: 20px;
    object-fit: cover;
}
 
.comerciante-images {
    display: flex;
    overflow-x: auto;
}

.carousel-button {
    position: absolute;
    display: flex;
    align-items: center;
    background-color: none;
    justify-content: center;
    width: 30px;
    top: 50%;
    border: none;
    padding: 10px;
    cursor: pointer;
    background-color: transparent;
    & span {
        color: #fff;
    }
    
}
  
  .previous-button {
    left: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .next-button {
    right: 10px;
  }