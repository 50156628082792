.categorias {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    width: 100%;
    padding-top: 10px;
    align-items: center;
    margin-bottom: 20px;
    & h1 {
        margin-top: 0px;
    }
}

.categorias div {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #424242;
    & h1 {
        font-size: 17px;
    }
}

.categorias span {
    font-size: 50px;
    border-radius: 50%;
    color: #67179e;
}

.catalog {
    width: 100%;
    margin-top: 50px;
    height: auto;
}

.catalog .cidade {
    width: 100%;
    display: flex;
    justify-content: center;
}

.catalog .cidade .cidade-button{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    background-color: #fff;
    flex-direction: row;
    & p {
        margin-right: 5px;
        font-size: 16px;
    }
}

.li-div-cidade {
    display: flex;
    align-items: center;
    font-size: 18px;
}

.catalog .cidade .cidade-button span{
    color: #67179e;
}

.catalog .input-div .search-bar {
    border: none;
    outline: none;
    width: 100%;
    font-size: 17px;
}

.link-busca {
    width: 100%;
}

.login-button {
    position: fixed;
    right: 1px;
    top: 5px;
    border: none;
    background-color: #67179e;
}

.catalog .destaques {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 10px;
    width: 100%;
    height: 150px;
    margin-left: -10px;
    background-color: #67179e;
    margin-bottom: 20px;
    overflow-y: hidden;
    overflow-x: auto;
    white-space: nowrap;
    scrollbar-width: thin;
}

.catalog .destaques-title {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    & span {
        color: #FBC02D;
    }
}

.catalog .card {
    padding-top: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: 9px;
    width: 170px;
    border-radius: 8px;
    border: #9e9e9e solid 1px;
    min-height: 145px;
    background-color: #f5f5f5;
    & p {
        font-size: 15px;
        font-weight: 500;
        text-align: center;
        color: #000;
        margin: 0;
        word-wrap: break-word;
        white-space: normal;
    }
    & h1 {
        font-size: 16px;
        color: #67179e;
        margin-top: 0;
        margin-bottom: 0;
        margin: 0;
        word-wrap: break-word;
        white-space: normal;
        padding-bottom: 5px;
    }
    & span {
        color: #67179e;
    }
    & div {
        display: flex;
        flex-direction: row;
    }
}

.catalog .card .profile-image {
    width: 100px;
    height: 100px;
    object-fit: cover; 
    border-radius: 8px;
    overflow: hidden;
  }

.catalog .comerciantes {
    display: flex;
    justify-content: center;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    padding: 10px;
    width: 101%;
    margin-bottom: 60px;
    margin-left: -16px;
    & .card {
        margin-bottom: 10px;
    }
}

.modal-cidade {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
    overflow-y: auto;
  }

.close-modal-cidade {
    position: relative;
    margin-left: -95%;
    margin-top: -6%;
    background-color: #fff;
    & span {
        color: #67179e;
    }
}
  
  .modal-content-cidade {
    display: flex;
    align-items: center;
    flex-direction: column;
    background: white;
    height: 90%;
    overflow-y: auto;
    padding: 20px;
    border-radius: 5px;
    width: 300px; /* Ajuste conforme necessário */
    text-align: center;
    font-size: 18px;
    & img {
        max-width: 200px;
    };
    & span {
        color: #67179e;
    }
  }
  
  .modal-content-cidade ul {
    list-style: none;
    padding: 0;
  }
  
  .modal-content-cidade li {
    padding: 10px;
    cursor: pointer;
  }
  
  .modal-content-cidade li:hover {
    background-color: #f0f0f0; /* Efeito hover */
  }
  
.header-desktop {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    width: 100%;
    top: 0;
    max-height: 10%;
    background-color: #fff;
    padding: 5px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.15);
}

.header-desktop .content {
    display: flex;
    width: 70%;
    height: 10%;
    align-items: center;
    justify-content: space-between;
    & img {
        max-width: auto;
        max-height: 9vh;
        cursor: pointer;
    }
    & .login-button-desktop {
        display: flex;
        align-items: center;
        color: #fff;
        background: #67179E;
        cursor: pointer;
        & span {
            color: #fff;
        }
    }
}

.header-desktop .input-div {
    margin-bottom: 0;
    width: 40%;
    & .search-bar {
        width: 100%;
        border: none;
        outline: none;
        font-size: 18px;
    }
}

.header-categoria-desktop {
    display: flex;
    flex-direction: column;
    align-items: center;
    & label {
        font-size: 16px;
    }
    & .categorias-title  {
        cursor: pointer;
        & label {
            padding-left: 0;
            cursor: pointer;
        }
    }
    & .categorias-dropdown {
        display: none;
        flex-direction: column;
        position: absolute;
        margin-top: 20px;
        box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;  
    }
    & div {
        width: 120px;
        display: flex;
        align-items: left;
        justify-content: center;
        flex-direction: row;
        background-color: #fff;
        & div{
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: row;
            & h1 {
                color: #424242;
            }
            & span {
                font-size: 18px;
                color: #67179e;
                margin-right: 2px;
                padding-bottom: 9px;
            }
        }
        & h1 {
            font-size: 16px;
        }
        & span {
            font-size: 18px;
            color: #67179e;
        }
    }
}

.categorias-dropdown :hover {
    background-color: #f0f0f0;
}

.header-categoria-desktop:hover .categorias-dropdown {
    display: flex;
}

.catalog-desktop .cidade .cidade-button:hover {
    background-color: #f0f0f0;
}

.catalog-desktop {
    margin-top: 10vh;
    width: 70%;
    min-height: 90vh;
}

.catalog-desktop .cidade {
    & .cidade-button {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: row;
        background-color: #fff;
        color: #000;
        font-size: 16px;
        color: #67179e;
        cursor: pointer;
        & span {
            color: #67179e;
        }
        & p {
            color: #000;
            font-size: 18px;
            margin-right: 5px;
        }
    }
}

.catalog-desktop .destaques {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 10px;
    width: 100%;
    margin-left: -10px;
    margin-bottom: 20px;
    justify-content: space-around;
    flex-wrap: wrap;
}

.destaques .card-destaque {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 400px;
    border-radius: 8px;
    overflow: hidden;
    margin-bottom: 15px;
    border: #9e9e9e solid 1px;
    height: 150px;
    cursor: pointer;
    background-color: #f5f5f5;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.15);
    & p {
        font-size: 16px;
        font-weight: 500;
        color: #000;
        margin: 0;
    }
    & h1 {
        font-size: 16px;
        color: #67179e;
        margin-top: 0;
        margin-bottom: 0;
    }
    & span {
        color: #67179e;
    }
    & div {
        display: flex;
        flex-direction: row;
    }
}

.destaques .card-destaque:hover {
    box-shadow: 0 2px 10px #67179e;
}

.destaques .card-destaque .destaque-infos {
    min-width: 50%;
    height: 80%;
    justify-content: center;
    display: flex;
    flex-direction: column;
    padding-left: 5px;
    align-items: start;
    & .nome {
        margin-bottom: 10px;
        font-size: 19px;
    }
}

.card-destaque .profile-image-div {
    min-width: 50%;
    & img {
        width: 100%;
        height: auto;
        object-fit: cover;
    }
}

.catalog-desktop .destaques-title {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    & span {
        color: #FBC02D;
    }
}

.baseboard-desktop {
    position: relative;
    width: 100.9%;
    height: 15%;
    margin-bottom: -10px;
    left: 0;
    background-color: #67179e;
    display: flex;
    align-items: center;
    justify-content: center;
}

.baseboard-desktop .left {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50%;
    max-height: 200px;
    overflow: hidden;
    & img {
        width: 90%;
        height: 100%;
        object-fit: cover;
        object-position: center;
    }
}

.baseboard-desktop .right {
    display: flex;
    align-items: center;
    justify-content: start;
    width: 50%;
    max-height: 300px;
    min-height: 150px;
    padding-left: 10px;
    border-left: #fff 1px solid;
    & img {
        width: 25px;
        height: auto;
        object-fit: cover;
        margin-right: 5px;
    }
    & div {
        padding-bottom: 8px;
        display: flex;
        align-items: center;
        justify-content: start;
        & a {
            text-decoration: none;
            color: #fff;
            font-size: 16px;
        }
        & p {
            color: #fff;
            margin-bottom: 0;
            margin-top: 0;
            font-size: 16px;
        }
    }
}

.baseboard-socials {
    flex-direction: column;
    & div {
        width: 100%;
    }
}

.divider {
    height: 1px;
    background-color: #c9c9c9;
    width: 70vw;
    margin-top: 5px;
}

.dev {
    position: relative;
    left: 0;
    display: flex;
    align-items: center;
    margin-bottom: -10px;
    width: 98.2%;
    height: 30px;
    padding-left: 50px;
    background-color: #5a1d83;
    color: #fff;
    font-size: 14px;
    & span {
        color: #fff;
    }
}

.contato {
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-bottom: 50px;
    width: 100%;
    & p {
        display: flex;
        flex-direction: row;
        margin-bottom: 0;
        font-size: 20px;
        font-weight: 500;
        color: #424242;
    }
    & .vitrinne {
        margin-top: 0;
        margin-left: 5px;
        margin-right: 5px;
        color: #811bc5 !important;
    }
    & .click-contato {
        margin-top: 0;
        margin-left: 5px;
        margin-right: 5px;
        color: #811bc5;
        cursor: pointer;     
    }
}

.contato .click-contato:hover {
    text-decoration: underline;
}

.voltar {
    display: flex;
    align-items: center;
    & .link { 
        display: flex;
        align-items: center;
        color: #67179e;
        & span {
            color: #67179e;
        }
    }
}

.modal-comercianteDetails {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}
.modal-comercianteDetails .modal-content{
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    max-width: 30%;
    height: 70%;
    & .close-button {
        background-color: transparent;
        & span {
            color: #000;
            border-radius: 50px;
            background-color: #fff;
            font-weight: 500;
        }
    }
}