.dashboard-container {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 60px;
  overflow: auto;
  overflow-x: hidden;
}

.logout-button {
  position: fixed;
  left: 1px;
  top: 5px;
  border: none;
  cursor: pointer;
  background-color: #67179e;
  & span {
    transform: rotate(180deg);
    color: #fff;
  }
}

.input-div-dashboard {
  width: 50%;
  padding: 10px;
  display: flex;
  align-items: center;
  margin-right: 5px;
  border: solid 2px #d1d1d1;
  background-color: #fff;
  border-radius: 5px 5px 5px 5px;
}

.input-div-dashboard .search-bar {
  border: none;
  outline: none;
  width: 100%;
  font-size: 19px;
}

h1 {
  text-align: center;
  margin-bottom: 20px;
}

.dashboard-table {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.dashboard-filter {
  display: flex;
  align-items: center;
  flex-direction: row;
  width: 90%;
  padding: 8px;
  & p {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40%;
    font-size: 20px;
    & span {
      font-size: 38px;
      color: #67179e;
    };
  }
}

.dashboard-table .actions {
  position: absolute;
  margin-left: -30px;
  margin-top: 10px;
}

.dashboard-table .actions button {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 18px;
}

.dashboard-table .mini-menu {
  position: absolute;
  top: 10px;
  left: 20px;
  background-color: white;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.15);
  display: flex;
  flex-direction: column;
  padding: 8px 0;
  z-index: 100;
  width: 120px;
}

.dashboard-table .mini-menu button {
  background: none;
  border: none;
  padding: 8px 16px;
  display: flex;
  flex-direction: row;
  cursor: pointer;
  text-align: left;
  width: 100%;
}

.dashboard-table .mini-menu button:hover {
  background-color: #f0f0f0;
}

.dashboard-table table {
  padding: 10px;
  border-collapse: collapse;
  margin-bottom: 20px;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}
  
.dashboard-table th {
  padding: 8px;
  text-align: center;
}

td {
  padding: 5px;
  min-width: 200px;
  min-height: 100px;
  text-align: center;
}

tr {
  border: #f1f1f1 solid 1px;
}

.td-div-comerciante {
  display: flex;
  flex-direction: row;
  align-items: center;
  & span {
    color: #FBC02D;
    font-size: 18px;
    position: absolute; 
  }
  & label {
    font-size: 16px;
    padding: 0;
  }
  & p {
    margin-top: 0;
    text-align: left;
    margin-bottom: 0;
    color: #000000bd;
    font-size: 14px;
  }
  & div {
    padding-left: 5px;
    padding-right: 10px;
    display: flex;
    flex-direction: column;
  }
}
  
.dashboard-table th {
  background-color: #f1f1f1;
  cursor: default;
}

.dashboard-table td img.profile-image {
  width: 50px;
  height: 50px;
  border-radius: 5px;
  object-fit: cover;
}

.dashboard-table tr:nth-child(even) {
  background-color: #f9f9f9;
}

.dashboard-table tr:hover {
  background-color: #f1f1f1;
}

.button-cadastrar {
  width: 250px;
  height: 50px;
  outline: none;
  background-color: rgb(67, 167, 67);
  cursor: pointer;
  color: #fff;
  border: solid 2px #fff;
}

.modal-delete-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-delete-content {
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  width: 300px;
  text-align: center;
  & div {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
  }
  & button {
    display: flex;
    align-items: center;
    cursor: pointer;
  }
}

.inativo {
  display: flex;
  align-items: center;
  justify-content: center;
  & div  {
    display: flex;
    align-items: center;
    padding: 5px;
    justify-content: center;
    width: 50%;
    background-color: #FFEBEE !important;
    color: #E53935;
    border-radius: 8px;
    & span {
      color: #E53935;
      justify-self: center;
    }
  }
}

.ativo {
  display: flex;
  align-items: center;
  justify-content: center;
  & div  {
    display: flex;
    align-items: center;
    padding: 5px;
    justify-content: center;
    width: 50%;
    background-color: #E8F5E9 !important;
    color: #388E3C;
    border-radius: 8px;
    & span {
      color: #388E3C;
      justify-self: center;
    }
  }
}

.td-tipo-div {
  display: flex;
  align-items: center;
  justify-content: center;
  & div  {
    display: flex;
    align-items: center;
    justify-content: center;
    & span {
      color: #67179e;
      justify-self: center;
    }
  }
}

@media screen and (max-width: 768px) {
  .dashboard-container .card {
    padding-top: 5px;
    padding-left: 5px;
    display: flex;
    flex-direction: row;
    align-items: center;
    border-radius: 8px;
    border: #9e9e9e solid 1px;
    width: 310px;
    height: 100px;
    background-color: #f5f5f5;
    & h1 {
        font-size: 18px;
        color: #000;
        margin-top: 0;
        margin-bottom: 0;
    }
    & span {
        color: #67179e;
    }
    & div {
        display: flex;
        flex-direction: column;
    }
  }

  #cadastro-button-mobile {
    background-color: rgb(67, 167, 67);
    color: #fff;
    position: fixed;
    bottom: 5%;
    right: 5%;
    font-size: 50px;
    border-radius: 50%;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
  }

  .card-info {
    display: flex;
    align-items: start;
    padding-left: 5px;
  }

  .card-info div {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row !important;
    & label {
      font-size: 16px;
      text-wrap: nowrap;
      padding-left: 0;
    }
  }

  #inativo { 
    background-color: #FFEBEE !important;
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    border-radius: 5px;
    margin-bottom: 5px;
  }

  .status-inativo {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: -5px;
    margin-bottom: -10px;
    & span {
      color: #E53935;
    }
  }

  .card .profile-image {
    width: 120px;
    height: 95px;
    margin-bottom: 5px;
    border-radius: 5px;
    object-fit: cover;
  }

  .input-div-dashboard {
    width: 90%;
    margin-left: 10px;
    padding: 10px;
    display: flex;
    align-items: center;
    border: solid 2px #d1d1d1;
    background-color: #fff;
    border-radius: 5px 5px 5px 5px;
  }
  
  .input-div-dashboard .search-bar {
    border: none;
    outline: none;
    width: 100%;
    font-size: 16px;
  }

  .comerciantes-dashboard {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    padding: 10px;
    width: 100%;
    overflow-y: auto !important;
    margin-bottom: 60px;
    margin-left: -10px;
    & .card {
        margin-bottom: 10px;        
    }
    & #destaque {
      color: #FBC02D;
    }
  }
  h1 {
    font-size: 20px;
  }
}