.busca {
    width: 100%;
    height: auto;
    & label {
        font-size: 18px;
        font-style: italic;
    }
}

.busca .input-div {
    margin-bottom: 5px;
}

.busca .input-div .search-bar {
    border: none;
    outline: none;
    width: 100%;
    font-size: 17px;
}

.busca .card {
    padding-top: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: 9px;
    width: 170px;
    border-radius: 8px;
    border: #9e9e9e solid 1px;
    min-height: 145px;
    background-color: #f5f5f5;
    & p {
        font-size: 15px;
        font-weight: 500;
        text-align: center;
        color: #000;
        margin: 0;
        word-wrap: break-word;
        white-space: normal;
    }
    & h1 {
        font-size: 16px;
        color: #67179e;
        margin-top: 0;
        margin-bottom: 0;
        margin: 0;
        word-wrap: break-word;
        white-space: normal;
        padding-bottom: 5px;
    }
    & span {
        color: #67179e;
    }
    & div {
        display: flex;
        flex-direction: row;
    }
}

.busca .card .profile-image {
    width: 100px;
    height: 100px;
    object-fit: cover; 
    border-radius: 8px;
    overflow: hidden;
}

.busca .comerciantes {
    display: flex;
    justify-content: center;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    padding: 10px;
    width: 101%;
    margin-bottom: 60px;
    margin-left: -16px;
    & .card {
        margin-bottom: 10px;
        & #destaque {
            position: absolute;
            color: #FBC02D;
        }
    }
}

.baseboard {
    display: flex;
    width: 100%;
    position: fixed;
    bottom: 0%;
    justify-content: space-around;
    background-color: #f5f5f5;
    border-top: solid 1px #d1d1d1;
  }
  
  .baseboard button {
    height: 50px;
  }