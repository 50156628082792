@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@20..48,100..700,0..1,-50..200');

.material-symbols-outlined {
  font-variation-settings:
  'FILL' 1,
  'wght' 400,
  'GRAD' 0,
  'opsz' 24
}

*{
  font-family: 'Roboto', sans-serif;
  transition: 0.5s;
  -webkit-user-modify: read-only;
  -webkit-user-select: text;
  -webkit-locale: 'pt-br';
  -webkit-translate: none;
  translate: no !important;
}

body {
  background-color: #fff;
}

h1 {
  font-size: 24px;
  font-weight: 500;
}

label {
  font-size: 20px;
  font-weight: 500;
  align-self: flex-start;
  padding-left: 20px;
}

button {
  padding: 10px;
  font-size: 18px;  
  font-weight: 500;
  border: none;
  border-radius: 5px 5px 5px 5px ;
}

span {
  color: #7e7e7e;
}

.main {
  width: 100%;
  max-width: 600px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.main form {
  width: 90%;
  justify-content: flex-start;
}

.main form label {
  font-size: 16px;
  font-weight: 400;
  padding: 0px;
}

.main form input {
  width: 95%;
  font-size: 16px;  
  font-weight: 500;
  border: none;
}

.main form input:focus {
  outline: none;
}

.main form button {
  width: 100%;
  margin-bottom: 5%;
  padding: 10px;
  font-size: 18px;  
  font-weight: 500;
  border: none;
  border-radius: 5px 5px 5px 5px ;
}

.input-div {
  width: 94%;
  padding: 10px;
  display: flex;
  align-items: center;
  margin-bottom: 5%;
  border: solid 2px #d1d1d1;
  background-color: #fff;
  border-radius: 5px 5px 5px 5px;
}

/* Header */
.header {
  display: flex;
  width: 100%;
  position: fixed;
  height: 60px;
  top: 0%;
  align-items: center;
  justify-content: center;
  background-color: #67179e;
}

.header img{
  max-width: 200px;
  max-height: 50px;
}

.login-button {
  & span {
    color: #fff;
  }
}

.header div {
  display: flex;
  flex-direction: row !important;
}

.header div button h1, :link{
  text-decoration: none;
  color: #fff;  
}

.header div button span {
  text-decoration: none;
  color: #fff;
  margin-left: 6px;
}

/* Baseboard */
.baseboard {
  display: flex;
  width: 100%;
  position: fixed;
  bottom: 0%;
  justify-content: space-around;
  background-color: #f5f5f5;
  border-top: solid 1px #d1d1d1;
}

.baseboard button {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 60px;
  width: 30%;
  background-color: #f5f5f5;
  color: #6a6a6a;
  text-decoration: none;
}

.baseboard button :link, :visited{
  color: #6a6a6a;
  text-decoration: none;
  font-size: 16px;
}

.baseboard button span{
  padding-left: 15%;
  margin-right: 12px;
}

.baseboard #selected a, #selected span{
  color: #67179e !important;
}

/* Media queries para ajustar o layout em telas menores */

@media screen and (max-width: 1920px) {
  .main {
      max-width: 100%;
  }
  h1 {
    font-size: 20px;
  }

}

@media screen and (max-width: 600px) {
  h1 {
    font-size: 20px;
  }

}

@media screen and (max-width: 400px) {
  h1 {
    font-size: 18px;
  }

}
