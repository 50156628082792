.modal {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    z-index: 1000;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
}

.modal-content {
    display: flex;
    align-items: center;
    flex-direction: column;
    background-color: #fff;
    padding: 25px;
    border-radius: 8px;
    max-width: 500px;
    width: 90%;
    height: 350px;
    position: relative;
    & input {
        width: 100px;
        font-size: 16px;
    }
}

#close {
    position: absolute;
    top: 5px;
    left: 5px;
    font-size: 24px;
    cursor: pointer;
}

.crop-button {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    max-width: 200px;
    margin-top: 50px;
    background: #6441A5;  /* fallback for old browsers */
    background: -webkit-linear-gradient(to left, #2a0845, #6441A5);  /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to left, #2a0845, #6441A5); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    color: #fff;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
    & span {
        color: #fff !important;
        margin-right: 5px;
    }
}
